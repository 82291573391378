function initApp() {
  const infoLink = document.getElementById('info');
  const bell = document.getElementById('bell');
  // already_read_admin_info が false の時、通常のベルマークは非表示
  const bellCheck = document.getElementById('bell-check');
  if (bell != undefined) { bell.style.display = "none" }
  if (infoLink) {
    infoLink.addEventListener('click', () => {
      const dropdown = document.getElementById('dropdown');
      // クリックで表示・非表示
      if (dropdown.classList.contains('is-active')) {
        dropdown.classList.remove('is-active')
      } else {
        dropdown.classList.add('is-active');
      }

      const user = document.getElementById('user');
      const alreadyRead = user.dataset.read;
      // already_read_admin_info が false → 未読のお知らせがある（チェックマーク付きのベルマークが表示）
      if (user && alreadyRead == 'false') {
        // チェックマーク付きのベルマークは非表示。通常のベルマークが表示。
        bell.style.display = "block";
        bellCheck.style.display = "none";

        // current_user.already_read_admin_info を true にする。
        const request = new XMLHttpRequest();
        const token = document.getElementsByName('csrf-token').item(0).content;
        request.open('PATCH', '/already_read_admin_info_to_true', true);
        request.setRequestHeader('X-CSRF-Token', token);
        request.addEventListener('load', () => {
          if (request.status !== 204) console.log('error');
        });
        request.send();
      }
    });
  }
}
document.addEventListener('DOMContentLoaded', initApp);
